import React, { FC, useRef, useState } from "react";
import { Button, Form, Image, Input, Modal, Segmented, Spin } from "antd";
import StatusCircleIcon from "../../assets/icons/StatusCircleIcon";
import styles from "./rightDrawer.module.scss";
import Element3Icon from "../../assets/icons/Element3Icon";
import CubeIcon from "../../assets/icons/CubeIcon";
import { useFlatDataBy } from "../../queries/queries";
import { useCreateLead, usePetitionCreate } from "../../queries/mutation";
import queryString from "query-string";
import { BASE_URL } from "../../services/RequestService";
import { defaultImage } from "../../utils/constants/defaultImage";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";

interface IProps {
  id: number;
  visible: boolean;
  setOpenDrawer: React.Dispatch<
    React.SetStateAction<{
      id: number;
      visible: boolean;
    }>
  >;
}

const RightDrawer: FC<IProps> = ({ visible, setOpenDrawer, id }) => {
  const { t } = useTranslation()
  const createLead = useCreateLead();
  const [openModal, setOpenModal] = useState<number | null>(null);
  const { data, isLoading, isFetching } = useFlatDataBy(id);
  const petitionCreate = usePetitionCreate(id);
  const [position, setPosition] = useState({
    startY: 0,
    startX: 0,
    scrollLeft: 0,
    scrollTop: 0,
    isDown: false,
  });
  const bodyRef = useRef<any>(null);

  const bodymouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (position.isDown) {
      e.preventDefault();
      const y = e.pageY - bodyRef.current.offsetTop;
      const walkY = y - position.startY;
      bodyRef.current.scrollTop = position.scrollTop - walkY;

      const x = e.pageX - bodyRef.current.offsetLeft;
      const walkX = x - position.startX;
      bodyRef.current.scrollLeft = position.scrollLeft - walkX;
    }
  };
  const bodymouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition((prev) => ({
      ...prev,
      isDown: false,
    }));
  };
  const bodymouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition({
      isDown: true,
      startY: e.pageY - bodyRef.current.offsetTop,
      startX: e.pageX - bodyRef.current.offsetLeft,
      scrollTop: bodyRef.current.scrollTop,
      scrollLeft: bodyRef.current.scrollLeft,
    });
  };
  const bodymouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition((prev) => ({
      ...prev,
      isDown: false,
    }));
  };
  const simple = queryString.parse(window.location.search)?.simple;
  const notPrices =
    queryString.parse(window.location.search)?.notPrices === "true";

  //petitionHundle
  const petitionHundle = () => {
    if (simple) {
      setOpenModal(id);
    } else {
      petitionCreate.mutate({
        flatId: String(id),
        leadId: String(queryString.parse(window.location.search).leadId),
      });
    }
  };

  //handleCloseModal
  const handleCloseModal = () => {
    setOpenModal(null);
  };

  //onFinish
  const onFinish = (values: any) => {
    const number = String(values.phone.replace(/[+()-]/g, ""));
    createLead
      .mutateAsync({
        flatId: id,
        name: values.name,
        phoneNumber: number,
        link: "",
      })
      .then(() => {
        setOpenModal(null);
      });
  };

  return (
    <div className={`${styles.container} ${visible ? styles.active : ""}`}>
      {visible ? (
        <span
          className={styles.closeIcon}
          onClick={() => {
            setOpenDrawer({ id: 0, visible: false });
          }}
        >
          &times;
        </span>
      ) : (
        ""
      )}
      <Spin spinning={isLoading || isFetching}>
        <div
          className={`${styles.rightDrawer} ${!simple && visible ? styles.rightDrawer_active : ""
            }  ${visible ? styles.rightDrawer_open : ""}`}
          onMouseMove={bodymouseMove}
          onMouseUp={bodymouseUp}
          onMouseLeave={bodymouseLeave}
          onMouseDown={bodymouseDown}
          ref={bodyRef}
        >
          <div className={styles.rightDrawer_container}>
            <div className={styles.rightDrawer_container_title}>
              <p>{t("Xonadon")} №{data?.number}</p>
              <span>
                <StatusCircleIcon
                  color={
                    data?.status === "SOLD"
                      ? "#FF6565"
                      : data?.status === "BOOKED"
                        ? "#FFB400"
                        : data?.status === "RESERVE"
                          ? "#A6A6A6"
                          : "#63CBA5"
                  }
                />
                {data?.status === "SOLD"
                  ? t("Sotilgan")
                  : data?.status === "BOOKED"
                    ? t("Band qilingan")
                    : data?.status === "RESERVE"
                      ? t("Sotilmaydi")
                      : t("Bo'sh")}
              </span>
            </div>
            {(simple ||
              (!data?.existFlatPetition && data?.status === "SALE")) && (
                <div className={styles.rightDrawer_container_petitionButton}>
                  <Button
                    onClick={petitionHundle}
                    loading={petitionCreate.isLoading}
                  >
                    {t("Ariza qoldirish")}
                  </Button>
                </div>
              )}
            {data?.gallery && (
              <div className={styles.rightDrawer_container_flatImage}>
                <Image
                  src={
                    BASE_URL +
                    "/" +
                    data.gallery.find((item) => item.position === "FLAT_PLAN")
                      ?.absolutePath
                  }
                  fallback={defaultImage}
                  preview={{
                    mask: t("Ko'rish"),
                    rootClassName: "preview-root",
                  }}
                />
                <Segmented
                  className="rightDrawer_container_flatImage_segmented"
                  disabled={true}
                  options={[
                    {
                      icon: <Element3Icon />,
                      value: "2D",
                      label: "2D tarx",
                    },
                    {
                      icon: <CubeIcon />,
                      value: "3D",
                      label: "3D tarx",
                    },
                  ]}
                />
              </div>
            )}
            {notPrices ? null : (
              <div className={styles.rightDrawer_container_flatPrice}>
                <h1>
                  {t("Narxi")}
                  <span>
                    {data?.price?.toLocaleString("ru")} {data?.ccy || "UZS"}
                  </span>
                </h1>
                <p>
                  {data?.pricePerArea?.toLocaleString("ru")}
                  {data?.ccy || "UZS"}
                  /m<sup>2</sup>
                </p>
              </div>
            )}
            <div className={styles.rightDrawer_container_flatInfo}>
              <h1>{t("Xonadon ma’lumotlari")}</h1>
              <div>
                <p>
                  {t("Bino")} <span>{simple ? data?.block : data?.buildingName}</span>
                </p>
                <p>
                  {t("Qavat")} <span>{data?.floor}</span>
                </p>
                <p>
                  {t("Xonadon raqami")} <span>{data?.number}</span>
                </p>
                <p>
                  {t("Xonalar soni")} <span>{data?.rooms}</span>
                </p>
                <p>
                  {t("Umumiy maydoni")}
                  <span>
                    {data?.area} m<sup>2</sup>
                  </span>
                </p>
                {notPrices ? null : (
                  <p>
                    <p>
                      {t("Boshlang'ich to'lov")} (
                      {data?.prepaymentPercent && (
                        <span>{`${data?.prepaymentPercent}%`}</span>
                      )}
                      )
                    </p>
                    <span>
                      {(data?.prepaymentAmount || 0)?.toLocaleString("ru")}
                      {data?.ccy || "UZS"}
                    </span>
                  </p>
                )}
                <p>
                  {t("To'lov muddati")} <span>{data?.delay} {t("oy")}</span>
                </p>
                {notPrices ? null : (
                  <p>
                    {t("Oylik to'lov")}
                    <span>
                      {(data?.monthlyPayment || 0)?.toLocaleString("ru")}
                      {data?.ccy || "UZS"}
                    </span>
                  </p>
                )}
                {data?.terraceRTO?.area ? (
                  <p>
                    {t("Terassa maydoni")}
                    <span>
                      {data?.terraceRTO?.area} m<sup>2</sup>
                    </span>
                  </p>
                ) : (
                  ""
                )}
              </div>
              {data?.petitionLeadList && data?.petitionLeadList.length > 0 && (
                <>
                  <h1>{t("Biriktirilgan leadlar")}</h1>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "0 10px",
                    }}
                  >
                    {data.petitionLeadList.map((item, index) => (
                      <a
                        key={index}
                        href={
                          "https://" +
                          item.subDomain +
                          "/leads/detail/" +
                          queryString.parse(window.location.search).leadId
                        }
                        target="_parent"
                      >
                        #{item.leadId}
                      </a>
                    ))}
                  </div>
                </>
              )}
              {/* <p>
            Barcha ma’lumotlar <ArrowDownIcon color="#4E73F8" />
          </p> */}
            </div>
          </div>
        </div>
      </Spin>
      <Modal
        visible={openModal != null}
        onCancel={handleCloseModal}
        footer={false}
        title={false}
        width={480}
        wrapClassName={styles.modal}
      >
        <Form onFinish={onFinish}>
          <h1>
            {t("description")}
          </h1>
          <Form.Item
            name={"name"}
            noStyle
            rules={[{ required: true, message: "" }]}
          >
            <Input placeholder={t("Ismingiz")} />
          </Form.Item>
          <Form.Item
            name={"phone"}
            noStyle
            rules={[{ required: true, message: "" }]}
          >
            <InputMask
              className="ant-input"
              mask="+\9\9\8(99)-999-99-99"
              placeholder={t("Telefon raqamingiz")}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Yuborish")}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default RightDrawer;
