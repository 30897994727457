import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// URL'dan tilni olish funksiyasi
const getLanguageFromURL = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get("lang") || "uz"; // Standart til: 'uz'
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "uz",
    debug: true,
    lng: getLanguageFromURL(),
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      lookupQuerystring: "lang",
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
  });

export default i18n;
