import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { AllHouseCopy, AllHouseFilter } from "../../atom";
import { useAllDataByFilter } from "../../queries/queries";
import BodyContainer from "../BodyContainer/BodyContainer";
import Filter from "../Filter/Filter";
import Header from "../Header/Header";
import RightDrawer from "../RightDrawer/RightDrawer";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation()
  const houseCopy = useRecoilValue<any>(AllHouseCopy);

  const [flatLength, setFlatLength] = useState([]);
  const [floorLength, setFloorLength] = useState(0);
  const [floorMaxCount, setFloorMaxCount] = useState(0);
  const filter = useRecoilValue<any>(AllHouseFilter);
  const {
    data: allDataByFilter,
    isLoading: allDataByFilterLoading,
    isFetching: allDataByFilterFetching,
    status: allDataByFilterStatus,
  } = useAllDataByFilter(filter);
  const [openDrawer, setOpenDrawer] = useState({ id: 0, visible: false });

  useEffect(() => {
    let floorMaxLen = 0;
    let floorMaxCount = 0;
    houseCopy &&
      houseCopy.length > 0 &&
      houseCopy?.forEach((building: any) => {
        building.forEach((entrance: any) => {
          if (floorMaxLen < entrance?.length) {
            floorMaxLen = entrance?.length;
          }
          entrance.forEach((floor: any) => {
            floor.forEach((flat: any) => {
              if (floorMaxCount < flat?.floor) {
                floorMaxCount = flat?.floor;
              }
            });
          });
        });
      });

    setFloorLength(floorMaxLen);
    setFloorMaxCount(floorMaxCount);
    // console.log(houseCopy);
  }, [houseCopy]);

  useEffect(() => {
    let arr: any = [];
    allDataByFilter?.buildingAmoCrmList?.forEach((item) => {
      item?.floorFlatList?.forEach((floor) => {
        floor?.entranceList?.forEach((entrance) => {
          entrance?.flatList?.forEach((flat) => {
            arr.push(flat);
          });
        });
      });
    });
    setFlatLength(arr);
  }, [allDataByFilter]);

  const simple = queryString.parse(window.location.search)?.simple;

  return simple ||
    ((allDataByFilterStatus === "loading" ||
      allDataByFilterStatus === "success") &&
      queryString.parse(window.location.search)?.login) ? (
    <div className="home">
      <Header />
      <div className="home_body">
        {!simple ? <Filter allDataByFilter={allDataByFilter} /> : <div></div>}

        <BodyContainer
          data={houseCopy}
          setOpenDrawer={setOpenDrawer}
          visible={openDrawer.visible}
          flatLength={flatLength.length}
          floorLength={floorLength}
          floorMaxCount={floorMaxCount}
          loading={allDataByFilterLoading}
          fetching={allDataByFilterFetching}
          flatId={openDrawer.id}
        />
        <RightDrawer
          visible={openDrawer.visible}
          setOpenDrawer={setOpenDrawer}
          id={openDrawer.id}
        />
      </div>
    </div>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#00a389",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "70px",
        color: "#fff",
        flexDirection: "column",
      }}
    >
      <p style={{ marginBottom: 0, fontWeight: 600 }}>403</p>
      {t("Ruxsat yo'q")}
    </div>
  );
};

export default Home;
