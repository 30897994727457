import React, { FC } from "react";
import styles from "./bodyContainer.module.scss";
import Building from "./Building";

interface IProps {
  setOpenDrawer: React.Dispatch<
    React.SetStateAction<{
      id: number;
      visible: boolean;
    }>
  >;
  data: any[];
  flatId: number;
}

const Entrance: FC<IProps> = ({ setOpenDrawer, data, flatId }) => {
  return (
    <div className={styles.bodyContainer_body_entrance}>
      <h1>{data[0][0][0]?.buildingName}</h1>
      <div className={styles.bodyContainer_body_entrance_building}>
        {data &&
          data.map((item) => (
            <Building
              key={item.floor}
              data={item}
              setOpenDrawer={setOpenDrawer}
              flatId={flatId}
            />
          ))}
      </div>
      <h1
        style={{
          top: "auto",
          bottom: "0",
        }}
      >
        {data[0][0][0]?.buildingName}
      </h1>
    </div>
  );
};

export default Entrance;
