import './services/i18n'

import React, { useEffect } from "react";
import "antd/dist/antd.variable.min.css";
import "./global.scss";
import Home from "./components/Home/Home";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { BrowserRouter, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const queryClient = new QueryClient();

const LanguageHandler = () => {
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const lang = searchParams.get("lang");
    if (lang && ["uz", "ru"].includes(lang)) {
      i18n.changeLanguage(lang);
    }
  }, [searchParams, i18n]);

  return null;
};


function App() {
  ConfigProvider.config({
    theme: {
      primaryColor: "#00A389",
    },
  });


  return (
    <BrowserRouter>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <LanguageHandler />
          <Home />
        </QueryClientProvider>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
